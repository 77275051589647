import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ToDoItem } from './todo.model';
import { SocketService } from 'src/app/core/service/socket.service';
import { AuthenticationService } from 'src/app/core/service/auth.service';

@Component({
    selector: 'app-widget-todo',
    templateUrl: './todo.component.html',
    styleUrls: ['./todo.component.scss']
})

export class TodoComponent implements OnInit, OnDestroy {

    @Input() toDoItems: ToDoItem[] = [];
    @Input() height: number = 0;
    @Input() archivable: boolean = false;
    @Input() addable: boolean = false;
    newTask: string = '';
    submitted: boolean = false;

    // @Output() archiveTodos = new EventEmitter<any>();
    // @Output() addTask = new EventEmitter<string>();

    @ViewChild('addTodo', { static: true }) addTodo!: any;

    constructor(private socketService: SocketService, private authService: AuthenticationService) { }

    _socketEvents() {
        this.socketService.on('todos-list', (data: any) => {
            this.toDoItems = data;
        });
        this.socketService.on('todo-added', (data: any) => {
            this.toDoItems.push(data);
        });
        this.socketService.on('todos-archived', (data: any) => {
            this._fetchTodoList();
        });
    }

    ngOnInit(): void {
        this._socketEvents();
        this._fetchTodoList();
    }

    ngOnDestroy(): void {
        this.socketService.off('todos-list');
        this.socketService.off('todo-added');
        this.socketService.off('todos-archived');
    }

    async _fetchTodoList(): Promise<void> {
        // this.todoList = TODOLIST;
        this.socketService.emit('loadTodos', { user: this.authService.currentUser()?._id });
    }

    /**
     * changes status of todo item
     * @param todoItem todo item
     */
    toggleTodo(todoItem: ToDoItem): void {
        todoItem.completed = !todoItem.completed;
    }

    /**
     * archive completed tasks
     */
    archiveTasks(): void {
        this.archiveTodos();
    }

    /**
     * add new task in todo list
     */
    addNewTask(): void {
        this.submitted = true;
        if (this.newTask !== '') {
            this.addTask(this.newTask);
            this.newTask = '';
            this.submitted = false;
        }
    }

    /**
     * Returns the remaining todos
     */
    getInprogressTodoCount(): number {
        return this.toDoItems.filter((todoItem: ToDoItem) => todoItem.completed === false).length
    }


    addTask(newTask: string): void {
        if (!newTask) return;
        this.socketService.emit('addTodo', { text: newTask });
    }


    /**
     * Archives the todos
     */
    archiveTodos(): void {
        this.socketService.emit('archiveTodos', this.toDoItems.filter(e => e.completed).map(e => e._id));
    }
}
